import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import IndexArticle from '../components/IndexArticle'

export default function Home({ data }) {
  return (
    <div>
      <SEO />
      <Layout>
        <h1>list</h1>
        {data.allMdx.edges.map(({ node }) => (
          <div style={{ margin: '10px 0' }} key={node.id}>
            <Link to={node.fields.slug}>
              <IndexArticle node={node}></IndexArticle>
            </Link>
          </div>
        ))}
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(width: 300, height: 300)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
