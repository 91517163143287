import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './index.module.scss'

export default function IndexArticle({ node }) {
  let image = null
  if (node.frontmatter.image) {
    image = (
      <GatsbyImage
        image={getImage(node.frontmatter.image)}
        alt={`image of ${node.frontmatter.title}`}
      ></GatsbyImage>
    )
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>{image}</div>
      <div className={styles.texts}>
        <h2>{node.frontmatter.title}</h2>
        <span>{node.frontmatter.description}</span>
      </div>
    </div>
  )
}
